<template>
  <v-content>
    <router-view id="content-wrap" />
  </v-content>
</template>

<script>
export default {
  name: 'DashboardCoreView'
};
</script>

<style scoped>
#dashboard-core-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
>
